/** 审批状态枚举 */
export const AUDIT_STATUS_ENUM = {
  ing: {
    value: 20,
    label: '平台介入中',
  },
  reject: {
    value: 40,
    label: '平台已驳回',
  },
  return: {
    value: 30,
    label: '平台已退款',
  }
}

/** 缺货原因枚举 */
export const LOSS_REASON_ENUM = {
  loss: {
    value: 10,
    label: '运损'
  },
  lack: {
    value: 20,
    label: '缺货'
  },
  quality: {
    value: 30,
    label: '质量问题'
  },
  unreal: {
    value: 40,
    label: '货不对板'
  },
  lacking: {
    value: 50,
    label: '缺斤少两'
  },
  origin: {
    value: 60,
    label: '差价'
  }
}
